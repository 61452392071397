<template>
  <v-card color="white">
    <v-card-title>
      <v-icon>mdi-bank</v-icon>
      <h3 class="h5 ml-2 dark-color">{{ $t("bank_acc") }}</h3>
    </v-card-title>
    <v-card-text class="text-left">
      <v-list color="white">
        <v-list-item
          class="bank-acc"
          v-for="(acc, i) in this.user_bank_accounts"
          :key="i"
          @click="open_bank_dialog(acc)"
        >
          <v-list-item-content>
            <v-list-item-title class="dark-color">{{
              get_bank_name(acc)
            }}</v-list-item-title>
            <v-list-item-subtitle v-if="!acc.IsForeign">
              <span>{{ $t("account") }} {{ acc.Account }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              <span>{{ $t("beneficiary") }} {{ acc.Beneficiary }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon class="edit-btn" color="grey lighten-1">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div class="text-center">
        <v-btn
          color="primary"
          @click="open_bank_dialog(null)"
          rounded
          class="mr-0 mt-6 white-color"
          >{{ $t("new_bank_acc") }}</v-btn
        >
      </div>
      <v-dialog v-if="dialog" v-model="dialog" scrollable>
        <AddBankAccountModal
          :BankAcc="bank_acc"
          @close="dialog = false"
          @add="add_account"
          @edit="edit_account"
        ></AddBankAccountModal>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.edit-btn {
  display: none;
}
.bank-acc {
  &:hover {
    .edit-btn {
      display: block !important;
    }
  }
}
</style>

<script>
import AddBankAccountModal from "../form/AddBankAccountModal";
import banksData from "@/components/dxa/BankAccount/banks.json";
export default {
  name: "BankAccountsProfile",
  props: {
    Profile: Object,
  },
  components: {
    AddBankAccountModal,
  },
  data: () => ({
    bank_acc: {
      UserId: null,
      Bank: null,
      Agency: null,
      Account: null,
    },
    banks: banksData,
    dialog: false,
  }),
  async created() {
    this.user_bank_accounts = this.Profile.BankAccounts.map((acc, index) => {
      return {
        Id: acc.Id,
        BankAccountId: acc.BankAccount.Id,
        Bank: acc.BankAccount.Bank,
        Agency: acc.BankAccount.Agency,
        Account: acc.BankAccount.Account,
        UserId: acc.UserId,
        IsForeign: false,
      };
    });

    this.Profile.InterBankAccounts.map((acc, index) =>
      this.user_bank_accounts.push({
        Id: acc.Id,
        BankAccountId: acc.InterBankAccount.Id,
        Bank: acc.InterBankAccount.Bank,
        Account: acc.InterBankAccount.Account,
        Address: acc.InterBankAccount.Address,
        OtherInformation: acc.InterBankAccount.OtherInformation,
        Beneficiary: acc.InterBankAccount.Recipient,
        ABA: acc.InterBankAccount.RoutingNumber,
        SWIFT: acc.InterBankAccount.Swift,
        UserId: acc.UserId,
        IsForeign: true,
      })
    );
  },
  computed: {},
  methods: {
    get_bank_name(acc) {
      var banks_filtered = this.banks.filter((x) => x.COMPE == acc.Bank);
      if (banks_filtered.length > 0) {
        return banks_filtered[0].LongName;
      }
      return acc.Bank;
    },
    open_bank_dialog(acc) {
      if (acc != null) {
        this.bank_acc = acc;
      } else {
        this.bank_acc = {
          UserId: this.Profile.Id,
          Bank: null,
          Agency: null,
          Account: null,
          Beneficiary: null,
          Address: null,
          OtherInformation: null,
          SWIFT: null,
          ABA: null,
          IsForeign: false,
        };
      }
      this.dialog = true;
    },
    add_account(account) {
      this.user_bank_accounts.push(account);
      this.dialog = false;
    },
    edit_account(account) {
      var index = this.user_bank_accounts.indexOf(this.bank_acc);
      this.user_bank_accounts[index] = account;
      this.dialog = false;
    },
  },
};
</script>
