<template>
  <v-container v-if="all_loaded" id="profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" :md="profile.Type != UserTypeEnum.Admin ? 8 : 12">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <div
            :elevation="hover ? 16 : 2"
            :class="{
              'on-hover': hover,
              profileImageInvestor: profile.Type == UserTypeEnum.Investor,
              profileImageOther: profile.Type != UserTypeEnum.Investor,
            }"
            @click="openFilePicker"
          >
            <v-img
              style="
                cursor: pointer;
                border-radius: 5%;
                height: 100%;
                display: flex;
                justify-content: center;
              "
              v-if="profile.Base64Image != null || profile.Image != null"
              :src="showPhoto()"
            >
              <div
                style="
                  height: 100%;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                "
              >
                <v-btn
                  :class="{ 'show-btns': hover }"
                  class="ma-auto"
                  color="transparent"
                  icon
                >
                  <v-icon
                    x-large
                    :class="{ 'show-btns': hover }"
                    color="transparent"
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </div>
            </v-img>
            <v-img
              style="cursor: pointer; border-radius: 5%"
              src="../../../assets/profile.png"
              v-else
            >
              <div
                style="
                  height: 100%;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                "
              >
                <v-btn
                  :class="{ 'show-btns': hover }"
                  class="ma-auto"
                  color="transparent"
                  icon
                >
                  <v-icon
                    x-large
                    :class="{ 'show-btns': hover }"
                    color="transparent"
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </div></v-img
            >
            <input
              id="imageProfileInput"
              name="imageProfileInput"
              ref="imageProfileInput"
              type="file"
              accept="image/png, image/jpeg, image/bmp"
              style="display: none"
              @change="setImageProfile"
            />
          </div>
        </v-hover>
        <v-card class="profileCard" color="white">
          <h3 class="h4 profile-header dark-color">{{ showName() }}</h3>
          <v-tabs show-arrows v-model="tab" background-color="white">
            <v-tab data-test="Investor:Profile:PersonalDataTab">{{
              $t("personal_data")
            }}</v-tab>

            <v-tab
              v-if="profile.Type == UserTypeEnum.Investor"
              data-test="Investor:Profile:PortfolioProfileTab"
              >{{ $t("portfolio_profile") }}</v-tab
            >

            <v-tab
              v-if="profile.Type == UserTypeEnum.Investor"
              data-test="Investor:Profile:FilesTab"
            >
              {{ $t("files") }}
            </v-tab>

            <v-tab
              v-if="profile.Type == UserTypeEnum.Investor"
              data-test="Investor:Profile:StatementTab"
            >
              {{ $t("statements") }}
            </v-tab>
          </v-tabs>
          <Basic
            v-if="tab == profileTabsEnums.UserData"
            :Profile="profile"
          ></Basic>

          <!-- container par ao componente UserPreferences, para atualizar o conteúdo, precisamos deixar o userPreferencesLoading aqui no pai -->
          <div>
            <UserPreferences
              :editable="false"
              :loading="userPreferencesLoading"
              v-if="
                tab === profileTabsEnums.UserPortfolioPreferences &&
                !userPreferencesLoading
              "
              @btnConfirm="updatePreferences"
              btnText="save"
              :User="profile"
              :userType="UserTypeEnum.Investor"
            />
            <div class="items-center" v-if="userPreferencesLoading">
              <v-progress-circular
                indeterminate
                size="60"
                color="primary"
              ></v-progress-circular>
            </div>
          </div>
          <Files
            v-if="tab == profileTabsEnums.UserFiles"
            :Profile="profile"
          ></Files>
          <DashboardReportTable
            v-if="tab == profileTabsEnums.UserStatements"
            :userId="profile.Id"
            :reportType="ReportTypeEnum.Annual"
          ></DashboardReportTable>
        </v-card>
      </v-col>
      <v-col v-if="profile.Type == UserTypeEnum.Partner" cols="12" md="4">
        <Partner :Profile="profile"></Partner>
      </v-col>

      <v-col v-if="profile.Type == UserTypeEnum.Investor" cols="12" md="4">
        <BankAccounts :Profile="profile"></BankAccounts>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped>
.profile-header {
  position: absolute;
  top: 10px;
  left: 180px;
}
.show-btns {
  color: var(--dark) !important;
}
.on-hover {
  //transition: opacity .4s ease-in-out;
  background-color: var(--dark);
  opacity: 0.5;
}
.profileCard {
  padding: 10px;
  padding-top: 70px;
}
.profileImageInvestor {
  height: 120px !important;
  min-width: 120px !important;
  width: 48px !important;
  border-radius: 50% !important;
  position: absolute;
  z-index: 20;
  left: 60px;
  top: -5px;
}
.profileImageOther {
  height: 120px !important;
  min-width: 120px !important;
  width: 48px !important;
  border-radius: 50% !important;
  position: absolute;
  z-index: 20;
  left: 60px;
  top: -5px;
}

.items-center {
  display: grid;
  place-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 600px) {
  .profileImageOther {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .profileImageInvestor {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-header {
    position: initial;
    text-align: center;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import Basic from "./tabs/Basic";
import Portfolio from "./tabs/Portfolio";
import Files from "./tabs/Files";
import BankAccounts from "./tabs/BankAccounts";
import Partner from "./tabs/Partner";
import UserPreferences from "@/components/dxa/userPreferences/UserPreferences.vue";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { profileTabsEnums } from "./enums/ProfileEnum";
import { ReportTypeEnum } from "../../../shared/enums/ReportTypeEnum";
import DashboardReportTable from "@/components/dxa/Extract/ExtractTableComponent";

export default {
  name: "Profile",

  data: () => ({
    UserTypeEnum,
    ReportTypeEnum,
    apiService: new ApiService(),
    all_loaded: true,
    userPreferencesLoading: false,
    is_admin: false,
    tab: 0,
    profile_picture: {
      is64String: false,
      image: null,
    },
    profile: {
      Name: null,
      LastName: null,
      PostCode: null,
      City: null,
      State: null,
      Country: null,
      Address: null,
      Image: null,
    },
    profileTabsEnums,
  }),
  components: {
    Files,
    BankAccounts,
    Partner,
    UserPreferences,
    Basic,
    DashboardReportTable,
  },
  async created() {
    this.all_loaded = false;
    await this.get_profile();
  },
  computed: {},
  methods: {
    async updatePreferences() {
      this.userPreferencesLoading = true;
      await this.apiService
        .postRequest(`investor/update-preferences`, this.profile)
        .then(async (resp) => {
          this.$toast.success(`${this.$t("preference_change_successful")}`);
          await this.get_profile();
          this.userPreferencesLoading = false;
        })
        .catch((error) => {
          this.userPreferencesLoading = false;
          this.$toast.error(`${this.$t(`an_error_occurred`)}`);
        });
    },
    async get_profile() {
      await this.apiService
        .getRequest("user/profile")
        .then((resp) => {
          this.profile = JSON.parse(resp);
          this.profile_picture.image = this.profile.Image;
          this.all_loaded = true;
        })
        .catch((error) => {
          this.all_loaded = true;
        });
    },
    showName() {
      return this.profile.Name + " " + this.profile.LastName;
    },
    showPhoto() {
      if (this.profile.Base64Image != null) {
        return this.profile.Base64Image;
      } else {
        return this.profile.Image;
      }
    },
    openFilePicker() {
      this.$refs.imageProfileInput.click();
    },
    setImageProfile(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.profile.ImageFileExtension =
          event.target.files[0].type.split("/")[1];
        this.profile.Base64Image = reader.result;
      };
    },
  },
};
</script>
