<template>
  <div>
    <v-form ref="form" class="ma-6" @submit.stop.prevent="updateProfile()">
      <v-row align="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="Profile.Name"
            :label="$t('name')"
            class="purple-input my-input-style"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="Profile.LastName"
            :label="$t('last_name')"
            class="purple-input my-input-style"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="Profile.Email"
            :rules="[special_valid, accent_valid, email_valid]"
            :label="$t('email')"
            class="purple-input my-input-style"
          />
        </v-col>
      </v-row>
      <div v-if="Profile.ProfileDone == false || !checkComplyCube">
        <v-alert dense class="registerAlert" color="blue" elevation="3">
          <v-icon class="mr-2" color="white" x-large
            >mdi-alert-circle-outline
          </v-icon>
          <span class="text_alert" style="font-size: 14px">
            {{ $t("register_alert") }}
          </span>
          <v-btn
            class="btn_alert ml-1"
            @click="handleInvestorGoCompleteProfile($route.path)"
            data-test="Alpha:Basic:BtnCompleteRegister"
            >{{ $t("complete_registration") }}</v-btn
          >
        </v-alert>
      </div>

      <v-expansion-panels v-if="Profile.Type == UserTypeEnum.Investor">
        <v-expansion-panel>
          <v-expansion-panel-header
            :disabled="disabled_field"
            class="dark-color"
            color="white"
          >
            {{ $t("contact_profile") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row v-if="Profile.Type == UserTypeEnum.Investor">
              <v-col cols="12" sm="12" md="6">
                <v-combobox
                  dense
                  :items="countries"
                  item-text="name"
                  return-object
                  @change="nationality_code_changed"
                  :rules="[required]"
                  v-model="nationalitySelected"
                  :label="$t('nationality')"
                  class="my-input-style"
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items --><span
                      class="mx-2"
                      style="text-transform: none"
                      >{{ data.item.name }}</span
                    >
                  </template></v-combobox
                >
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-if="Profile.Nationality == 'Brazil'"
                  @keydown.space.prevent
                  dense
                  v-mask="
                    Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson
                      ? '###.###.###-##'
                      : '##.###.###/####-##'
                  "
                  type="tel"
                  :rules="[required, social_number_valid]"
                  v-model="Profile.SocialNumber"
                  :label="
                    Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson
                      ? $t('cpf')
                      : $t('company_id')
                  "
                  class="my-input-style"
                />
                <v-text-field
                  v-else
                  @keydown.space.prevent
                  dense
                  type="tel"
                  :rules="[required]"
                  v-model="Profile.SocialNumber"
                  :label="
                    Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson
                      ? $t('passport')
                      : $t('company_id')
                  "
                  class="my-input-style"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="date"
                  v-mask="'##/##/####'"
                  @change="birth_changed"
                  hint="dd/mm/yyyy"
                  :label="
                    Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson
                      ? $t('birth_date')
                      : $t('establishment_date')
                  "
                  prepend-inner-icon="mdi-calendar"
                  :rules="[required, validDate, min18Years]"
                  class="my-input-style"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  @keydown.space.prevent
                  class="my-input-style"
                  v-model="Profile.CelPhone"
                  :label="$t('celphone')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  class="my-input-style"
                  @keydown.space.prevent
                  v-model="Profile.HomePhone"
                  :label="$t('home_phone')"
                />
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  v-model="Profile.Address"
                  :label="$t('address')"
                  class="purple-input my-input-style"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  :label="$t('number')"
                  class="purple-input my-input-style"
                  v-model="Profile.AddressNumber"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  :label="$t('complement')"
                  class="purple-input my-input-style"
                  v-model="Profile.AddressComplement"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  class="purple-input my-input-style"
                  :label="$t('state')"
                  v-model="Profile.State"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('neighborhood')"
                  class="purple-input my-input-style"
                  v-model="Profile.Neighborhood"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.Country"
                  :label="$t('country')"
                  class="purple-input my-input-style"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.City"
                  :label="$t('city')"
                  class="purple-input my-input-style"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.PostCode"
                  class="purple-input my-input-style"
                  :label="$t('post_code')"
                />
              </v-col>
              <v-col cols="12">
                <p class="mx-auto" style="color: var(--dark)">
                  {{ $t("account_type") }}
                </p>
                <v-radio-group center v-model="Profile.JuridicalType">
                  <v-radio :value="JuridicalTypeEnum.NaturalPerson">
                    <template v-slot:label>
                      <span style="color: var(--dark)">{{
                        $t("fisical_person")
                      }}</span>
                    </template></v-radio
                  >
                  <v-radio :value="JuridicalTypeEnum.LegalPerson">
                    <template v-slot:label>
                      <span style="color: var(--dark)">{{
                        $t("legal_person")
                      }}</span>
                    </template></v-radio
                  >
                </v-radio-group>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson"
        >
          <v-expansion-panel-header
            :disabled="disabled_field"
            class="dark-color"
            color="white"
          >
            {{ $t("patrimonial_info") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row v-if="Profile.Type == UserTypeEnum.Investor">
              <v-col cols="12">
                <v-text-field
                  :label="$t('total_assets')"
                  v-model="Profile.TotalAssets"
                  v-money="money"
                  class="purple-input my-input-style"
                />
              </v-col>
              <v-col cols="12">
                <span style="color: var(--dark)">
                  {{ $t("assets_origins") }}
                </span>
                <div class="mt-4 mx-12">
                  <v-row align="center">
                    <v-col
                      v-for="(asset, i) in assetOptions"
                      :key="i"
                      cols="12"
                      md="6"
                      class="my-0 py-0"
                    >
                      <v-checkbox
                        class="my-0 py-0"
                        v-model="selectedAssets"
                        :value="asset.id"
                      >
                        <template v-slot:label>
                          <span style="color: var(--dark)">{{
                            $t(asset.key)
                          }}</span>
                        </template></v-checkbox
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            :disabled="disabled_field"
            v-if="Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson"
            class="dark-color"
            color="white"
          >
            {{ $t("personal_docs") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row v-if="Profile.Type == UserTypeEnum.Investor">
              <v-col cols="12">
                <span class="dark-color">
                  {{ $t("document_type") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group center v-model="Profile.DocumentType">
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("rg") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("cnh") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("passport")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="Profile.DocumentNumber"
                  class="purple-input my-input-style"
                  :label="$t('document_number')"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="Profile.Issuer"
                  class="purple-input my-input-style"
                  :label="$t('issuing_body')"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="Profile.IssuerState"
                  class="purple-input my-input-style"
                  :label="$t('state')"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  ref="menuDateIssuer"
                  v-model="menuDateIssuer"
                  :close-on-content-click="false"
                  :return-value.sync="dateIssuer"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateIssuer"
                      :label="$t('expedition_date')"
                      prepend-inner-icon="mdi-calendar"
                      class="my-input-style"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="mx-auto"
                    v-model="dateIssuer"
                    @change="
                      $refs.menuDateIssuer.save(dateIssuer);
                      menuDateIssuer = false;
                      date_issuer_changed();
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="Profile.MotherName"
                  class="purple-input my-input-style"
                  :label="$t('mother_name')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="Profile.FatherName"
                  class="purple-input my-input-style"
                  :label="$t('father_name')"
                />
              </v-col>
              <v-col cols="12">
                <span class="dark-color">
                  {{ $t("legal_representative_question") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="Profile.HasLegalRepresentative"
                  >
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="Profile.JuridicalType == JuridicalTypeEnum.LegalPerson"
        >
          <v-expansion-panel-header
            :disabled="disabled_field"
            class="dark-color"
            color="white"
          >
            {{ $t("juridical_info") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.CompanyName"
                  class="purple-input my-input-style"
                  :label="$t('company_name')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.StateRegistration"
                  class="purple-input my-input-style"
                  :label="$t('state_registration')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.MainEconomicActivity"
                  class="purple-input my-input-style"
                  :label="$t('main_economic_activity')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.TaxAddress"
                  class="purple-input my-input-style"
                  :label="$t('tax_address')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.CNAE"
                  class="purple-input my-input-style"
                  :label="$t('cnae')"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="
            Profile.JuridicalType == JuridicalTypeEnum.LegalPerson &&
            Profile.HasLegalRepresentative
          "
        >
          <v-expansion-panel-header
            :disabled="disabled_field"
            class="dark-color"
            color="white"
          >
            {{ $t("legal_representative_header") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.LegalRepresentative.Name"
                  class="purple-input my-input-style"
                  :label="$t('name')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.LegalRepresentative.SocialNumber"
                  class="purple-input my-input-style"
                  :label="$t('cpf')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <span>
                  {{ $t("link_type") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="Profile.LegalRepresentative.LinkType"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("legal_representative")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("attorney")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row> </v-col
              ><v-col cols="12" md="4">
                <span>
                  {{ $t("marital_status") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="Profile.LegalRepresentative.MaritalStatus"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("single")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("married")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("widower")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="3">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("divorced")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.LegalRepresentative.Nationality"
                  class="purple-input my-input-style"
                  :label="$t('nationality')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.LegalRepresentative.Profession"
                  class="purple-input my-input-style"
                  :label="$t('profession')"
                />
              </v-col>

              <v-col cols="12" md="3">
                <span>
                  {{ $t("document_type") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="Profile.LegalRepresentative.DocumentType"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("rg") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("cnh") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("passport")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.LegalRepresentative.DocumentNumber"
                  class="purple-input my-input-style"
                  :label="$t('document_number')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.LegalRepresentative.Issuer"
                  class="purple-input my-input-style"
                  :label="$t('issuing_body')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.LegalRepresentative.IssuerState"
                  class="purple-input my-input-style"
                  :label="$t('state')"
                /> </v-col
              ><v-col cols="12" md="4">
                <v-menu
                  ref="menuDateLegalRepresentative"
                  v-model="menuDateLegalRepresentative"
                  :close-on-content-click="false"
                  :return-value.sync="dateLegalRepresentative"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateLegalRepresentative"
                      :label="$t('expedition_date')"
                      @change="
                        Profile.LegalRepresentative.ExpeditionDate =
                          dateLegalRepresentative.toString() + 'T00:00:00'
                      "
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="mx-auto"
                    v-model="dateLegalRepresentative"
                    @change="
                      $refs.menuDateLegalRepresentative.save(
                        dateLegalRepresentative
                      );
                      menuDateLegalRepresentative = false;
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <span>
                  {{ $t("non_resident_investor_question") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="Profile.HasNonResidentInvestor"
                  >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="
            Profile.JuridicalType == JuridicalTypeEnum.LegalPerson &&
            Profile.HasNonResidentInvestor
          "
        >
          <v-expansion-panel-header :disabled="disabled_field" color="white">
            {{ $t("non_resident_investor") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="Profile.NonResidentInvestor.RDE"
                  class="purple-input my-input-style"
                  :label="$t('rde')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="
                    Profile.NonResidentInvestor.CountryLegalRepresentative
                  "
                  class="purple-input my-input-style"
                  :label="$t('country_representative')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="
                    Profile.NonResidentInvestor
                      .CountryLegalRepresentativeSocialNumber
                  "
                  class="purple-input my-input-style"
                  :label="$t('cpf')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="Profile.NonResidentInvestor.TaxRepresentative"
                  class="purple-input my-input-style"
                  :label="$t('tax_representative')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="
                    Profile.NonResidentInvestor.TaxRepresentativeSocialNumber
                  "
                  class="purple-input my-input-style"
                  :label="$t('cpf')"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="Profile.JuridicalType == JuridicalTypeEnum.LegalPerson"
        >
          <v-expansion-panel-header
            :disabled="disabled_field"
            color="white"
            class="dark-color"
          >
            {{ $t("stock_position") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-btn
              color="primary"
              class="mt-8 mr-9"
              small
              rounded
              @click="open_stock_position_dialog(null)"
              absolute
              fab
              top
              right
            >
              <v-icon small color="white">mdi-plus</v-icon>
            </v-btn>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="Profile.NetWorth"
                  type="number"
                  prefix="R$"
                  @change="Profile.NetWorth = parseFloat(Profile.NetWorth)"
                  class="purple-input my-input-style"
                  :label="$t('net_worth')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="Profile.AverageMonthlyRevenue"
                  type="number"
                  prefix="R$"
                  @change="
                    Profile.AverageMonthlyRevenue = parseFloat(
                      Profile.AverageMonthlyRevenue
                    )
                  "
                  class="purple-input my-input-style"
                  :label="$t('average_monthly_revenue')"
                />
              </v-col>
            </v-row>
            <v-simple-table style="width: 100%; background: var(--white)">
              <thead>
                <tr>
                  <th class="text-center">{{ $t("name") }}</th>
                  <th class="text-center">{{ $t("cpf") }}</th>
                  <th class="text-center">{{ $t("nationality") }}</th>
                  <th class="text-center">{{ $t("capital") }}</th>
                  <th class="text-center">{{ $t("options") }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(sp, index) in Profile.StockPositions" :key="index">
                  <td class="text-center dark-color">{{ sp.Name }}</td>
                  <td class="text-center dark-color">
                    {{ sp.RegistryNumber }}
                  </td>
                  <td class="text-center dark-color">{{ sp.Nationality }}</td>
                  <td class="text-center dark-color">{{ sp.Capital }}%</td>
                  <td class="text-center">
                    <v-btn
                      class="px-2 ml-1 secondary"
                      @click="open_stock_position_dialog(sp)"
                      min-width="0"
                      small
                    >
                      <v-icon small color="white">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      class="px-2 ml-1"
                      @click="open_delete_dialog_stock_position(sp)"
                      color="red"
                      min-width="0"
                      small
                    >
                      <v-icon small color="white">mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="Profile.JuridicalType == JuridicalTypeEnum.LegalPerson"
        >
          <v-expansion-panel-header
            :disabled="disabled_field"
            color="white"
            class="dark-color"
          >
            {{ $t("equity_holding") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row>
              <v-btn
                color="primary"
                class="mt-8 mr-9"
                small
                rounded
                @click="open_equity_holding_dialog(null)"
                absolute
                fab
                top
                right
              >
                <v-icon small color="white">mdi-plus</v-icon>
              </v-btn>
              <v-simple-table style="width: 100%; background: var(--white)">
                <thead>
                  <tr>
                    <th class="text-center">{{ $t("name") }}</th>
                    <th class="text-center">{{ $t("cpf") }}</th>
                    <th class="text-center">{{ $t("nationality") }}</th>
                    <th class="text-center">{{ $t("capital") }}</th>
                    <th class="text-center">
                      {{ $t("is_politically_exposed") }}
                    </th>
                    <th class="text-center">{{ $t("options") }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(eh, index) in Profile.EquityHoldings"
                    :key="index"
                  >
                    <td class="text-center dark-color">{{ eh.Name }}</td>
                    <td class="text-center dark-color">
                      {{ eh.RegistryNumber }}
                    </td>
                    <td class="text-center dark-color">{{ eh.Nationality }}</td>
                    <td class="text-center dark-color">{{ eh.Capital }}%</td>
                    <td class="text-center dark-color">
                      {{ eh.PoliticallyExposed ? $t("yes") : $t("no") }}
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="px-2 ml-1 secondary"
                        @click="open_equity_holding_dialog(eh)"
                        min-width="0"
                        small
                      >
                        <v-icon small color="white">mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        class="px-2 ml-1"
                        @click="open_delete_dialog_equity_holding(eh)"
                        color="red"
                        min-width="0"
                        small
                      >
                        <v-icon small color="white">mdi-trash-can</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- AQUI -->
        <v-expansion-panel
          v-if="Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson"
        >
          <v-expansion-panel-header
            :disabled="disabled_field"
            class="dark-color"
            color="white"
          >
            {{ $t("professional_info") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row v-if="Profile.Type == UserTypeEnum.Investor">
              <v-col cols="12" md="4">
                <v-autocomplete
                  :filter="filterObject"
                  v-model="Profile.ProfessionId"
                  :items="profession_options"
                  :item-text="translateProfessionOptions"
                  item-value="id"
                  :label="$t('profession')"
                  class="field purple-input my-input-style"
                  @keyup="getProfession"
                  id="profession_input"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{
                          profession_text_follback
                            ? $t("write_your_profession")
                            : $t("profession_not_found")
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.ProfessionalOccupation"
                  class="purple-input my-input-style"
                  :label="$t('professional_occupation')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Profile.Employer"
                  class="purple-input my-input-style"
                  :label="$t('employer')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="Profile.EmployerNumber"
                  class="purple-input my-input-style"
                  :label="$t('company_id')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="Profile.EmployerPhone"
                  class="purple-input my-input-style"
                  :label="$t('phone')"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="Profile.EmployerAddress"
                  class="purple-input my-input-style"
                  :label="$t('address')"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="Profile.MonthlyIncome"
                  @change="
                    Profile.MonthlyIncome = parseFloat(Profile.MonthlyIncome)
                  "
                  type="number"
                  class="purple-input my-input-style"
                  :label="$t('monthly_income')"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            color="white"
            :disabled="disabled_field"
            v-if="Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson"
            class="dark-color"
          >
            {{ $t("statements") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
            <v-row v-if="Profile.Type == UserTypeEnum.Investor">
              <v-col cols="12" md="3">
                <span class="dark-color">
                  {{ $t("marital_status") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group center v-model="Profile.MaritalStatus">
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("single")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("married")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("widower")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="3">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("divorced")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <span class="dark-color">
                  {{ $t("sex") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group center v-model="Profile.Sex">
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("woman")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("man") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("other")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <span class="dark-color">
                  {{ $t("is_politically_exposed") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group center v-model="Profile.PoliticallyExposed">
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <span class="dark-color">
                  {{ $t("is_us_person") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group center v-model="Profile.USPerson">
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-right mt-6">
        <v-btn
          color="primary"
          type="submit"
          :disabled="disabled_field"
          :loading="loading"
          class="mr-0 white-color"
          >{{ $t("update_profile") }}</v-btn
        >
      </div>
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </v-form>
    <v-dialog v-model="dialogEquityHolding" v-if="dialogEquityHolding">
      <AddEquityHolding
        :Obj="equityHolding"
        :UserId="Profile.Id"
        @update="update_equity_holding"
        @close="dialogEquityHolding = false"
      ></AddEquityHolding>
    </v-dialog>
    <v-dialog v-model="dialogStockPosition" v-if="dialogStockPosition">
      <AddStockPosition
        :Obj="stockPosition"
        :UserId="Profile.Id"
        @update="update_stock_position"
        @close="dialogStockPosition = false"
      ></AddStockPosition>
    </v-dialog>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog">
      <DeleteConfirmationModal
        :obj="equityHolding ? equityHolding : stockPosition"
        :name="
          equityHolding ? $t('this_equity_holding') : $t('this_stock_position')
        "
        @close="deleteDialog = false"
        @delete="delete_obj"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
:disabled {
  background: #ccc;
}
.registerAlert {
  background-color: rgba(33, 150, 243, 0.5) !important;
  border: 2px solid #2196f3 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}
.btn_alert {
  border: 1px solid var(--white);
  background-color: rgba(33, 150, 243, 0.5) !important;
  box-sizing: border-box;
  border-radius: 10px;
}
.text_alert {
  color: var(--white);
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import AddStockPosition from "../form/AddStockPositionModal";
import AddEquityHolding from "../form/AddEquityHoldingModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import moment from "moment";
import countriesJson from "@/utils/countries.json";
import { VMoney } from "v-money";
import {
  formatToDecimalNumber,
  convertIntNumberInDecimal,
} from "@/shared/helpers/inputHelpers.js";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";
import { handleInvestorGoCompleteProfile } from "@/shared/helpers/completeProfile";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "BasicProfile",
  props: {
    Profile: Object,
  },
  components: {
    AddStockPosition,
    AddEquityHolding,
    DeleteConfirmationModal,
  },
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    vs: new ValidationService(),
    dialogEquityHolding: false,
    dialogStockPosition: false,
    loading: false,
    stockPosition: null,
    equityHolding: null,
    date: null,
    dateIssuer: null,
    countries: countriesJson,
    nationalitySelected: null,
    menuDateIssuer: false,
    assetOptions: [],
    error: null,
    success: null,
    alert_msg: null,
    dateLegalRepresentative: null,
    menuDateLegalRepresentative: null,
    deleteDialog: false,
    disabled_field: true,
    profession_options: [],
    profession_text_follback: true,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$",
      precision: 2,
      masked: false,
    },
    directives: { money: VMoney },
    selectedAssets: null,
    JuridicalTypeEnum,
    handleInvestorGoCompleteProfile,
    checkComplyCube: true,
    UserTypeEnum,
    currentEmail: null,
  }),
  async created() {
    this.checkProfession();
    await this.getAssetsOptions();
    this.isDisabled();

    this.currentEmail = this.Profile.Email.trim();

    if (this.Profile.Type == UserTypeEnum.Investor) {
      await this.getComplyCubePendingIssues();

      if (this.Profile.Birth) {
        this.date = moment(this.Profile.Birth).format("DD/MM/YYYY");
        if (moment(this.Profile.Birth).format("YYYY") == "0001") {
          this.date = null;
        }
      }
      if (this.Profile.ExpeditionDate) {
        this.dateIssuer = moment(this.Profile.ExpeditionDate).format(
          "DD/MM/YYYY"
        );
        if (moment(this.Profile.ExpeditionDate).format("YYYY") == "0001") {
          this.dateIssuer = null;
        }
      }
      if (
        this.Profile.LegalRepresentative &&
        this.Profile.LegalRepresentative.ExpeditionDate
      ) {
        this.dateLegalRepresentative = moment(
          this.Profile.LegalRepresentative.ExpeditionDate
        ).format("DD/MM/YYYY");
        if (
          moment(this.Profile.LegalRepresentative.ExpeditionDate).format(
            "YYYY"
          ) == "0001"
        ) {
          this.dateLegalRepresentative = null;
        }
      }
      if (this.Profile.Nationality == null) {
        this.nationalitySelected = this.countries.filter(
          (x) => x.name == "Brazil"
        )[0];
        this.Profile.Nationality = "Brazil";
      } else {
        this.nationalitySelected = this.countries.filter(
          (x) => x.name == this.Profile.Nationality
        )[0];
      }

      if (this.Profile.AssetResources.length) {
        this.selectedAssets = this.Profile.AssetResources;
      }
    }

    if (this.Profile.TotalAssets) {
      this.Profile.TotalAssets = convertIntNumberInDecimal(
        this.Profile.TotalAssets
      );
    }
  },
  computed: {
    social_number_valid() {
      return (value) =>
        (value &&
          ((value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
            .length == 11 &&
            this.vs.cpf_test(
              value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
            )) ||
            (value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
              .length == 14 &&
              this.vs.cnpj_test(
                value
                  .replaceAll(".", "")
                  .replaceAll("/", "")
                  .replaceAll("-", "")
              )))) ||
        this.$t("format_invalid");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    min18Years() {
      return () =>
        this.Profile.JuridicalType == this.JuridicalTypeEnum.LegalPerson ||
        (this.date &&
          moment().diff(
            moment([
              parseInt(this.date.split("/")[2]),
              parseInt(this.date.split("/")[1]) - 1,
              parseInt(this.date.split("/")[0]),
            ]),
            "years"
          ) >= 18) ||
        this.$t("min_18");
    },
    validDate() {
      return () =>
        (this.date &&
          parseInt(this.date.split("/")[2]) > 1600 &&
          moment([
            parseInt(this.date.split("/")[2]),
            parseInt(this.date.split("/")[1]) - 1,
            parseInt(this.date.split("/")[0]),
          ]).isValid()) ||
        this.$t("valid_date");
    },
    special_valid() {
      return (v) =>
        !/[*|\":<>[\]{}`\\()';&$]/.test(v) || this.$t("special_char");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
    email_valid() {
      return (v) =>
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          v
        ) || this.$t("format_invalid");
    },
  },
  methods: {
    async getComplyCubePendingIssues() {
      const user = JSON.parse(localStorage.getItem("user"));

      await this.apiService
        .getRequest(`backgroundcheck/checks-pending/${user.id}`)
        .then((resp) => {
          const complyCubeChecks = resp.content;

          if (complyCubeChecks && complyCubeChecks.length > 0) {
            this.checkComplyCube = false;
          } else {
            this.checkComplyCube = true;
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    isDisabled() {
      if (
        this.Profile.Type != this.UserTypeEnum.Investor ||
        this.Profile.ProfileDone == true
      ) {
        this.disabled_field = false;
      }
    },

    nationality_code_changed() {
      if (this.nationalitySelected.name) {
        this.Profile.Nationality = this.nationalitySelected.name;
      }
    },
    async updateProfile() {
      let result = this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.loading = true;
      this.alert_msg = null;
      if (this.Profile.Type == this.UserTypeEnum.Investor) {
        this.Profile.AssetResources = this.selectedAssets;
      }

      if (this.Profile.TotalAssets) {
        this.Profile.TotalAssets = formatToDecimalNumber(
          this.Profile.TotalAssets
        );
      }

      await this.apiService
        .putRequest("user/profile/update", this.Profile)
        .then((resp) => {
          const { user, token } = resp.content;
          const userToString = JSON.stringify(user);

          this.success = true;
          this.alert_msg = this.$t("saved");

          // se alterar o email, vamos forçar o login do usuário.
          if (this.currentEmail !== user.email.trim()) {
            window.localStorage.clear();
            this.$toast.warning(this.$t("change_login_alert"));
            this.$router.push("/auth");
          } else {
            this.$store.commit("SET_LOGGED_USER", userToString);
            this.$store.commit("SET_CURRENT_TOKEN", token);
          }
        })
        .catch((error) => {
          this.success = false;
          this.alert_msg = error.body.message;
        });

      this.loading = false;
    },
    date_issuer_changed() {
      this.Profile.ExpeditionDate = this.dateIssuer.toString() + "T00:00:00";
    },
    birth_changed() {
      let date = this.date.split("/");
      this.Profile.Birth = `${date[2]}-${date[1]}-${date[0]}T00:00:00`;
    },
    open_delete_dialog_equity_holding(obj) {
      this.stockPosition = null;
      this.equityHolding = obj;
      this.deleteDialog = true;
    },
    open_equity_holding_dialog(obj) {
      this.equityHolding = obj;
      this.dialogEquityHolding = true;
    },
    delete_equity_holding(obj) {
      try {
        let index = this.Profile.EquityHoldings.indexOf(obj);
        this.Profile.EquityHoldings.splice(index, 1);
        this.deleteDialog = false;
      } catch (err) {}
    },
    delete_obj(obj) {
      if (this.equityHolding == null) {
        this.delete_stock_position(obj);
      } else {
        this.delete_equity_holding(obj);
      }
    },
    open_delete_dialog_stock_position(obj) {
      this.stockPosition = obj;
      this.equityHolding = null;
      this.deleteDialog = true;
    },
    open_stock_position_dialog(obj) {
      this.stockPosition = obj;
      this.dialogStockPosition = true;
    },
    open_delete_dialog(obj) {
      this.stockPosition = obj;
      this.dialogStockPosition = true;
    },
    delete_stock_position(obj) {
      try {
        let index = this.Profile.StockPositions.indexOf(obj);
        this.Profile.StockPositions.splice(index, 1);
        this.deleteDialog = false;
      } catch (err) {}
    },
    update_equity_holding(obj) {
      if (this.equityHolding == null) {
        this.Profile.EquityHoldings.push(obj);
      } else {
        let index = this.Profile.EquityHoldings.indexOf(this.equityHolding);
        this.Profile.EquityHoldings[index] = obj;
      }
      this.dialogEquityHolding = false;
    },
    update_stock_position(obj) {
      if (this.stockPosition == null) {
        this.Profile.StockPositions.push(obj);
      } else {
        let index = this.Profile.StockPositions.indexOf(this.stockPosition);
        this.Profile.StockPositions[index] = obj;
      }
      this.dialogStockPosition = false;
    },
    async getProfession() {
      const professionInput = document.getElementById("profession_input");
      const professionValue = professionInput.value;
      const professionLength = professionInput.value.length;
      const language = localStorage.getItem("lang");

      if (professionValue.length >= 3) {
        await this.apiService
          .getRequest(`profession/${language}/${professionValue}`)
          .then((result) => {
            this.profession_options = result.content;
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
          });
      }

      if (professionLength < 3) {
        this.profession_text_follback = true;
      } else {
        this.profession_text_follback = false;
      }
    },
    checkProfession() {
      if (this.Profile.ProfessionId) {
        this.profession_options = [
          { id: this.Profile.ProfessionId, key: this.Profile.Profession },
        ];
      }
    },
    filterObject(item, queryText, itemText) {
      let translatedWord = this.$t(item.key);

      return (
        translatedWord
          .toLocaleLowerCase()
          .indexOf(itemText.toLocaleLowerCase()) > -1
      );
    },
    translateProfessionOptions(item) {
      return this.$t(item.key);
    },
    async getAssetsOptions() {
      await this.apiService
        .getRequest("assetresource/list")
        .then((result) => {
          this.assetOptions = result.content;
        })
        .catch(() => {
          this.$toast.error(this.$t("error_occoured"));
        });
    },
  },
};
</script>

<style scoped>
.my-input-style >>> .v-select__slot input,
.my-input-style >>> .v-select__slot span,
.my-input-style >>> .v-text-field__slot input,
.my-input-style >>> .v-text-field__slot div.v-text-field__prefix {
  color: var(--dark);
}
</style>
