<template>
  <div>
    <v-simple-table style="background: var(--white)">
      <thead>
        <tr>
          <th class="dark-color">{{ $t("name") }}</th>
          <th class="text-center dark-color">{{ $t("document_type") }}</th>
          <th class="text-center dark-color">
            {{ $tc("company", 1) }}
          </th>
          <th class="text-center dark-color">
            {{ $t("download") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(file, i) in Profile.Files" :key="i">
          <td class="dark-color">{{ file.FileName }}</td>
          <td class="text-center dark-color">
            {{
              file.UserFileType ? $t(documentTypeText(file.UserFileType)) : "-"
            }}
          </td>
          <td class="text-center dark-color">
            {{ file.CompanyName }}
          </td>
          <td class="text-center">
            <v-btn
              icon
              class="px-2 ml-1 btnDownloadFile"
              color="primary"
              @click="download_contract(file, file.FileName)"
              min-width="0"
              small
              :loading="fileDownloading == file.Path"
              :data-test="`Investor:Files:Download_${file.UserFileType}`"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
import moment from "moment";
import { userFileEnums } from "@/shared/enums/UserFileEnums";

export default {
  name: "FilesProfile",
  components: {},
  props: {
    Profile: Object,
  },
  data: () => ({
    apiService: new ApiService(),
    CommitmentTerms: [],
    fileDownloading: null,
    userFileEnums,
  }),
  async created() {
    this.CommitmentTerms = this.Profile.Interests.filter(
      (x) => x.CommitmentTerm != null
    );
    this.CommitmentTerms.map((x) => (x.Downloading = false));
  },
  computed: {},
  methods: {
    documentTypeText(documentType) {
      const literal = {
        [userFileEnums.MANAGED_PORTFOLIO_CONTRACT]:
          "managed_portfolio_contract",
        [userFileEnums.INVESTMENT_PROXY]: "investment_proxy",
        [userFileEnums.COMMITMENT_TERM]: "commitment_term",
        [userFileEnums.CONTRACT_RENEW]: "contract_renew",
        [userFileEnums.ALPHA_CONTRACT_AMENDMENT]: "alpha_contract_amendment",
        [userFileEnums.USE_TERMS]: "terms_use",
        [userFileEnums.NEWS_LETTER_SUBSCRIPTION]: "newsletter_subscription",
        [userFileEnums.ADHESION_TERM]: "adhesion_term_single",
        [userFileEnums.OTHERS]: "others",
        [userFileEnums.TRANSFER_RECEIPT]: "transfer_receipt_single",
      };

      return literal[documentType];
    },
    format_to_currency: function (value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    get_date_string(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    download_contract(doc, name) {
      if (name.indexOf(".") == -1) {
        name += ".pdf";
      }

      this.fileDownloading = doc.Path;
      this.apiService
        .getRequest(`investor/file?id=${doc.Id}`)
        .then((resp) => {
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          this.fileDownloading = null;
        })
        .catch((error) => {});
    },
  },
};
</script>
