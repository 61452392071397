<template>
  <v-card v-if="Profile.PartnerAuthToken">
    <v-card-text>
      <v-row>
        <v-col sm="12" md="12">
          <p class="colorpickerTitle">{{ $t("partner_auth_token") }}</p>
          <v-text-field
            v-model="Profile.PartnerAuthToken"
            :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (show = !show)"
            :type="show ? 'text' : 'password'"
          >
          </v-text-field>
          <div class="partner-copy-clipboard">
            <a @click="copy_to_clipboard()">
              {{ $t("click_copy_auth_token") }}
            </a>
            <font-awesome-icon
              icon="fa-solid fa-copy"
              width="16px"
              height="16px"
              color="var(--primary)"
              @click="copy_to_clipboard()"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.partner-copy-clipboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.partner-copy-clipboard > a {
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.partner-copy-clipboard > svg {
  cursor: pointer;
}
</style>

<script>
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "PartnerProfile",
  components: {},
  props: {
    Profile: Object,
  },
  data: () => ({
    show: false,
    gs: new GeneralServices(),
  }),
  async created() {
    if (this.Profile.CompanyColor == null) {
      const documentStyle = window.getComputedStyle(document.documentElement);
      let documentDarkColor = documentStyle.getPropertyValue("--dark");

      this.Profile.CompanyColor = documentDarkColor;
    }
  },
  computed: {},
  methods: {
    copy_to_clipboard() {
      this.gs.copy_to_clipboard(
        this.Profile.PartnerAuthToken,
        "auth_token_copied"
      );
    },
  },
};
</script>
